import React from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';
import BlogShare from '@components/blog/blogShare';
import RelatedPosts from '@components/blog/relatedPosts';

const BlogPost = ({ data: { page, relatedPosts } }) => {
  return (
    <Layout hideSub>
      <PageMeta {...page?.seo} />
      <div className="text-center py-space-m px-gutter">
        <h4 className="subThree">{page?.postDate}</h4>
        <h1 className="blockH0 my-one">{page?.title}</h1>
        <h4 className="blockH4 max-w-3xl mx-auto">{page?.excerpt}</h4>
      </div>
      {/*  */}
      <div className="px-gutter pb-space-s">
        <PortableTextBlock text={page?.post} className="max-w-8xl mx-auto" isBlog />
        <BlogShare />
      </div>
      <RelatedPosts posts={relatedPosts?.nodes} />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    page: sanityBlogPost(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      excerpt
      post: _rawPost(resolveReferences: { maxDepth: 10 })
      postDate: _createdAt(formatString: "MMMM DD, YYYY")
    }
    relatedPosts: allSanityBlogPost(
      filter: { slug: { current: { ne: $slug } } }
      limit: 3
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        postDate: _createdAt(formatString: "MMMM DD, YYYY")
        title
        excerpt
        postImage {
          ...ImageWithPreview
        }
        slug {
          current
        }
      }
    }
  }
`;
