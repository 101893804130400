import React from 'react';
import { Link } from 'gatsby';

import { SanityImage } from '@components/sanity/sanityImage';

const BlogCard = ({ title, postDate, excerpt, postImage, slug }) => {
  return (
    <Link to={`/news/${slug?.current}`}>
      <div className="aspect-w-1 aspect-h-1 relative">
        <SanityImage
          image={postImage}
          className="absolute top-0 left-0 object-cover w-full h-full"
        />
      </div>
      <div className="grid gap-y-2 mt-6">
        <h4 className="subThree">{postDate}</h4>
        <h2 className="blockH4 font-medium">{title}</h2>
        <p>{excerpt}</p>
      </div>
    </Link>
  );
};

export default BlogCard;
