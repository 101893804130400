import React from "react";
import Seo from "./seo";

export const PageMeta = ({
  metaTitle,
  metaDesc,
  shareDesc,
  shareTitle,
  shareGraphic,
  keywords,
  metaFallback = {},
  schema,
}) => {
  return (
    <Seo
      title={metaTitle || metaFallback.metaTitle}
      description={metaDesc || metaFallback.metaDesc}
      shareDesc={shareDesc || metaFallback.metaDesc}
      shareTitle={shareTitle || metaFallback.metaTitle}
      keywords={keywords}
      image={shareGraphic || metaFallback.shareGraphic}
      schema={schema}
    />
  );
};
