import React from 'react';
import { useLocation } from '@reach/router';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

const BlogShare = () => {
  const { href } = useLocation();

  return (
    <div className="flex gap-x-4 py-10 lg:px-32 lg:py-16">
      <h4 className="text-xl">Share this article:</h4>
      <FacebookShareButton url={href} className="underline">
        Facebook
      </FacebookShareButton>
      <TwitterShareButton url={href} className="underline">
        Twitter
      </TwitterShareButton>
      <LinkedinShareButton url={href} className="underline">
        Linkedin
      </LinkedinShareButton>
      <EmailShareButton url={href} className="underline">
        Email
      </EmailShareButton>
    </div>
  );
};

export default BlogShare;
