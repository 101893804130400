import React from 'react';

import BlogCard from './blogCard';

const RelatedPosts = ({ posts }) => {
  return (
    <div className="px-gutter bg-sand-900 py-32">
      <h2 className="text-2xl text-center mb-24 lg:text-[40px]">Other News</h2>
      <div className="grid grid-cols-3 gap-x-10">
        {posts.map((post) => (
          <BlogCard {...post} />
        ))}
      </div>
    </div>
  );
};

export default RelatedPosts;
